import {} from '../app'
import Swiper, {Navigation, Parallax} from 'swiper'

Swiper.use([Navigation, Parallax])
import {gsap} from "../mods/gsap";
import {float} from "quill/ui/icons";

// slider

new Swiper('[data-swiper="home"]', {
    speed: 3000,
    slidesPerView: 1,
    autoplay:{
        delay: 4000,
        disableOnInteraction: false
    },
    loop: true,
    parallax: true,
    navigation: {
        nextEl: '.next-slider',
        prevEl: '.prev-slider',
    }
})

new Swiper('[data-swiper="magasins"]', {
    speed: 1000,
    slidesPerView: 1,
    loop: false,
    spaceBetween: 30,
    parallax: true,
    navigation: {
        nextEl: '.next-magasins',
        prevEl: '.prev-magasins',
    },
    breakpoints: {
        1200: {
            slidesPerView: 3
        },
        550: {
            slidesPerView: 2
        },
        300: {
            slidesPerView: 1
        }
    }
})

new Swiper('[data-swiper="news"]', {
    speed: 1500,
    slidesPerView: 3,
    loop: true,
    parallax: true,
    pagination: {
        el: '.swiper-news-pagination',
        type: 'bullets',
    },
    breakpoints: {
        900: {
            slidesPerView: 3
        },
        450: {
            slidesPerView: 2
        },
        300: {
            slidesPerView: 1
        }
    }
})

new Swiper('[data-swiper="review"]', {
    speed: 1500,
    slidesPerView: 1,
    spaceBetween: 15,
    loop: true,
    parallax: true,
    pagination: {
        el: '.swiper-review-pagination',
        type: 'bullets',
        clickable: true
    }
})


// gsap animations

gsap.to('.selection-product .leave', 1, {
    skewX: '20%', scale: 1.2, scrollTrigger: {
        trigger: '.selection-product',
        //trigger scroller
        start: 'top 80%',
        scrub: true
    }
})

gsap.to('.range-section .leave', 1, {
    skewX: '-30%', scale: 1.2, scrollTrigger: {
        trigger: '.range-section',
        //trigger scroller
        start: 'top 80%',
        scrub: true
    }
})

gsap.to('.news-section .leave', 1, {
    skewX: '25%', scale: 1.2, scrollTrigger: {
        trigger: '.news-section',
        //trigger scroller
        start: 'top 80%',
        scrub: true
    }
})

// Batch helper function
function batch(targets, vars) {
    let varsCopy = {},
        interval = vars.interval || 0.1,
        proxyCallback = (type, callback) => {
            let batch = [],
                delay = gsap.delayedCall(interval, () => {callback(batch); batch.length = 0;}).pause();
            return self => {
                batch.length || delay.restart(true);
                batch.push(self.trigger);
                vars.batchMax && vars.batchMax <= batch.length && delay.progress(1);
            };
        },
        p;
    for (p in vars) {
        varsCopy[p] = (~p.indexOf("Enter") || ~p.indexOf("Leave")) ? proxyCallback(p, vars[p]) : vars[p];
    }
    gsap.utils.toArray(targets).forEach(target => {
        let config = {};
        for (p in varsCopy) {
            config[p] = varsCopy[p];
        }
        config.trigger = target;
        ScrollTrigger.create(config);
    });
}

/* batch : reveal on scroll */

function revealOnScroll(elements) {
    if ((elements).length) {
        gsap.set(elements, {autoAlpha: 0, y: '10%'})
        batch(elements, {
            onEnter: batch => gsap.to(batch, 1, {autoAlpha: 1, y: 0, stagger: .15, overwrite: true})
        })
    }
}

revealOnScroll('.product-item, .range-item, .reviews-item, .benefits-wrapper > div, .news-item, .swiper-review, .newsletter-section > *')

